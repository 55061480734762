import {
    ME_SUCCESS,
    USER_SUCCESS,
    LIST_SUCCESS,
    RUN_AS_LIST_SUCCESS,
    FIND_USER_SUCCESS,
    MANAGER_LIST_SUCCESS,
    ME_HIERARCHY_SUCCESS,
} from "actions/User";
import User from "entities/User";

export const users = (
    state = {
        me: new User(),
        users: [],
        vacationCount: {},
        listUsers: [],
        runAsListUsers: [],
        listManagers: [],
        craUser: null,
        meHierarchy: [],
    },
    action
) => {
    switch (action.type) {
        case ME_SUCCESS:
            let meJson = action.response.entities.user[action.response.result];
            let meEntity = new User(meJson, true);

            const rolesHaveChanged = () => {
                return !(
                    meJson.roles.length === state.me.roles.length &&
                    meJson.roles.sort().every(function (value, index) {
                        return value === state.me.roles.sort()[index];
                    })
                );
            };

            if (
                rolesHaveChanged() ||
                meJson.username !== state.me.username ||
                meJson.isActive !== state.me.isActive
            ) {
                return {
                    ...state,
                    me: meEntity,
                    vacationCount: {
                        cp: meJson.cp,
                        rtt: meJson.rtt,
                        beforeJune: meJson.before_june,
                        sickChildrenDays: meJson.sick_children_days,
                        remainder: meJson.remainder,
                        rttRemainder: meJson.rtt_remainder,
                    },
                };
            }

            return {
                ...state,
                vacationCount: {
                    cp: meJson.cp,
                    rtt: meJson.rtt,
                    beforeJune: meJson.before_june,
                    sickChildrenDays: meJson.sick_children_days,
                    remainder: meJson.remainder,
                    rttRemainder: meJson.rtt_remainder,
                },
            };

        case USER_SUCCESS:
            let users = [];
            if (action.response.entities.user) {
                let userArray = Object.values(action.response.entities.user);
                userArray.forEach((element) => {
                    users.push(new User(element));
                });
            }
            return {
                ...state,
                users: users,
            };
        case FIND_USER_SUCCESS:
            let craUser = null;
            if (action.response.entities.user) {
                craUser = new User(action.response.entities.user.user);
            }
            return {
                ...state,
                craUser: craUser,
            };
        case LIST_SUCCESS:
            let listUsers = [];
            if (action.response.entities.listUser) {
                let listArray = Object.values(
                    action.response.entities.listUser
                );
                listArray.forEach((element) => {
                    listUsers.push(new User(element));
                });
            }
            return {
                ...state,
                listUsers: listUsers,
            };
        case RUN_AS_LIST_SUCCESS:
            let runAsListUsers = [];
            if (action.response.entities.runAsListUser) {
                let listArray = Object.values(
                    action.response.entities.runAsListUser
                );
                listArray.forEach((element) => {
                    runAsListUsers.push(new User(element));
                });
            }
            return {
                ...state,
                runAsListUsers: runAsListUsers,
            };
        case MANAGER_LIST_SUCCESS:
            let listManagers = [];
            if (action.response.entities.managersList) {
                let userArray = Object.values(
                    action.response.entities.managersList
                );
                userArray.forEach((element) => {
                    listManagers.push(new User(element));
                });
            }
            return {
                ...state,
                listManagers: listManagers,
            };
        case ME_HIERARCHY_SUCCESS:
            let meHierarchy = [];
            if (action.response.entities.user) {
                meHierarchy = Object.values(action.response.entities.user)[0];
            }
            return {
                ...state,
                meHierarchy: meHierarchy,
            };

        default:
            return state;
    }
};

export default users;
