import React, {useEffect, useState} from "react";
import {changeOverlayContent} from "events/OverlayEvents";
import "./SecurityPopUp.scss";

const SecurityPopUp = (props) => {
    const {setPopupVisible} = props;

    const close = (e) => {
        e.preventDefault();
        localStorage.setItem("security2024Hidden", true);
        changeOverlayContent(null);
        setPopupVisible(false);
    };

    return <div id="security-popup">
        <div id="security-popup-close" onClick={(e) => close(e)}>
            <i className="fal fa-times close-icon"></i>
        </div>

        <div className="security-popup-header">
            <div className="security-popup-header-title">Sécurité : rappel des bonnes pratiques</div>
            <div className="security-popup-header-content">A l'approche des Jeux Olympiques d'été de Paris et compte
                tenu des enjeux de cybersécurité associés
                ainsi que de la situation géopolitique actuelle, nous souhaitons <strong>vous rappeler les bonnes
                    pratiques à respecter en matière de sécurité.</strong>
            </div>
        </div>

        <div className="security-popup-content-container">
            <img src="img/security/header.png" alt=""/>
            <div className="security-popup-content-scrollable">
                <div className="security-popup-content-title">En toutes circonstances :</div>
                <div className="security-popup-content">
                    <li><strong>Aucun transfert de données</strong> en dehors du SI de votre client n'est autorisé sans
                        autorisation préalable et écrite de votre correspondant opérationnel (qui doit être, dans ce
                        cas, en
                        copie de votre envoi).
                    </li>
                    <li>Le <strong>télétravail n'est possible que si votre client l'autorise,</strong> les modalités de
                        télétravail devant respecter les exigences du client et celles de Synchrone.
                    </li>
                    <li>Il est strictement <strong>interdit d'utiliser un wifi public</strong> quel qu'il soit.</li>
                    <li>Vous devez <strong>utiliser tous les moyens de sécurité</strong> mis en place sur votre poste de
                        travail (VPN, MFA, ...).
                    </li>
                    <li>Votre <strong>poste de travail doit être verrouillé</strong> dès que vous vous absentez même
                        pour
                        quelques minutes.
                    </li>
                    <li>Ne laissez <strong>jamais votre poste de travail sans surveillance</strong> lors de vos
                        déplacements
                        (trajets, restaurants, ...).
                    </li>
                    <li>Les documents utilisés et produits dans le cadre de votre mission sont la <strong>propriété
                        intellectuelle du client.</strong></li>
                    <li>Les documents peuvent être confidentiels et ne doivent en aucun cas être récupérés ou utilisés à
                        d'autres fin que celle de votre mission.
                    </li>
                    <li>Il est strictement interdit d'utiliser ou de connecter du matériel personnel à l'environnement
                        client (disques durs, téléphones, PC...)
                    </li>
                    <br/>
                    <div>Nous vous demandons par ailleurs la <strong><u>la plus grande attention quant aux tentatives de
                        phishing.</u></strong> Pour rappel:
                    </div>
                    <br/>
                    <li><strong>Personne n'a le droit de vous demander un mot de passe</strong> quel que soit le motif.
                    </li>
                    <li><strong>Vérifiez l’émetteur d’un e-mail</strong> dès que vous avez un doute ou que la demande
                        vous
                        parait suspecte.
                    </li>
                    <li><strong>Vérifiez que l’adresse qui s’affiche dans votre navigateur est conforme.</strong></li>
                    <div>Enfin, nous vous rappelons que <strong>tout ce que vous produisez chez un client est la
                        propriété
                        du client</strong> (document, feuille de calcul, présentation, outils, programmes, …). Vous
                        n’avez
                        pas le droit de le récupérer pendant ou en fin de prestation (sauf accord écrit du client
                        listant
                        précisément les documents et en accord avec les règles de sécurité du client).
                    </div>
                    <br/>
                    <div>En cas de doute ou si vous avez la moindre question, contactez le Pôle Sécurité <a
                        href="mailto:pole.securite@synchrone.fr"><strong><u>pole.securite@synchrone.fr</u></strong></a>
                    </div>
                    <br/>
                    <div>Nous vous remercions pour votre vigilance au quotidien.</div>
                    <br/>
                    <div>Le Pôle Sécurité Synchrone</div>
                </div>

            </div>
        </div>

        <div className="security-popup-footer"></div>

    </div>
}

export default SecurityPopUp